import React from 'react';
import { Dropdown } from 'primereact/dropdown';

function DashboardSort({ options, value, onChange }) {
  const arrowTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex items-center gap-2">
          <span className="font-medium">{option.sortLabel}</span>
          <i
            className={`pi pi-sort-${option.sortType}-${
              option.sortOrder === 1 ? 'up' : 'down'
            } text-sm`}></i>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  return (
    <Dropdown
      value={value}
      onChange={(e) => onChange(e.target.value)}
      options={options}
      optionLabel={'sortLabel'}
      placeholder="Trier par"
      className={`pl-1 items-center text-sm`}
      valueTemplate={arrowTemplate}
      itemTemplate={arrowTemplate}
    />
  );
}

export default DashboardSort;
