import React, { useMemo } from 'react';
import { getConstant } from '../../../../utils/constantHelper';
import { formatCity, formatDate } from '../../../../utils/stringHelper';

import Avatar from '../../../Display/Avatar';
import Data from '../../../Display/Data';
import DataTag from '../../../Display/DataTag';
import { Button } from 'primereact/button';

function PanelCollaboratorDetail({ collaborator, handleCollaboratorUpdate, selectCompany }) {
  const isOwner = useMemo(() => collaborator.type === 'parcel_owner', [collaborator]);

  const collaboratorType = useMemo(() => {
    if (!collaborator?.custom_user) return null;
    if (collaborator.type === 'internal')
      return getConstant('customUser.type', collaborator.custom_user.type).label;
    if (collaborator.type === 'parcel_owner')
      return getConstant('collaborator.subtype', collaborator.subtype).label;
    return getConstant('collaborator.type', collaborator.type).label;
  }, [collaborator]);

  return (
    <div className="w-full flex flex-col border-t pt-4 gap-y-5">
      <div className="flex flex-row w-full justify-between pr-1">
        <div className="flex flex-row gap-x-3">
          <Avatar alias={collaborator.alias} />
          <div className="flex flex-col justify-center text-slate-800">
            <span className="font-semibold text-slate-800">{collaborator.alias}</span>
            <span className="text-sm text-slate-600">{collaboratorType}</span>
          </div>
        </div>
        {collaborator.type !== 'internal' && (
          <Button
            key="edit"
            text
            rounded
            iconPos="left"
            icon={'pi pi-pencil'}
            onClick={handleCollaboratorUpdate}
          />
        )}
      </div>
      <div className="flex flex-col px-1 gap-y-5">
        <div className="flex flex-row gap-x-8">
          <Data
            key="firstName"
            label={'Prénom'}
            value={collaborator.first_name}
            bgColor={'bg-white'}
          />
          <Data key="name" label={'Nom'} value={collaborator.name} bgColor={'bg-white'} />
        </div>
        <Data key="email" label={'Email'} value={collaborator.email} large bgColor={'bg-white'} />
        <Data
          key="phone"
          label={'Téléphone'}
          value={collaborator.phone}
          large
          bgColor={'bg-white'}
        />
        <DataTag
          key="company"
          label={'Entreprise'}
          tag={{
            label: collaborator.company?.name,
            icon: 'company'
          }}
          bgColor={'bg-white'}
          onClick={() => collaborator.company && selectCompany(collaborator.company)}
          large
        />
        <Data
          key="address"
          label={'Adresse'}
          value={collaborator.address}
          large
          bgColor={'bg-white'}
        />
        <Data
          key="city"
          label={'Ville'}
          value={formatCity(collaborator.city)}
          large
          bgColor={'bg-white'}
        />
        {isOwner && (
          <div className="flex flex-row gap-x-8">
            <Data
              key="gender"
              label={'Genre'}
              value={getConstant('collaborator.gender', collaborator.gender).label}
              bgColor={'bg-white'}
            />
            <Data
              key="partner"
              label={'Conjoint'}
              value={collaborator.partner_name}
              bgColor={'bg-white'}
            />
          </div>
        )}
        {isOwner && (
          <div className="flex flex-row gap-x-8">
            <Data
              key="birthDate"
              label={'Date de naissance'}
              value={formatDate(collaborator.birth_dt)}
              bgColor={'bg-white'}
            />
          </div>
        )}
        {isOwner && (
          <Data
            key="city"
            label={'Ville de naissance'}
            value={formatCity(collaborator.birth_place)}
            bgColor={'bg-white'}
            large
          />
        )}
        <Data
          key="informations"
          label={'Notes internes'}
          value={collaborator.informations}
          large
          bgColor={'bg-white'}
        />
      </div>
    </div>
  );
}

export default PanelCollaboratorDetail;
