import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useMemo } from 'react';

function DashboardSearch({ value, handleSearch }) {
  const isActive = useMemo(() => value && value.length, [value]);

  return (
    <span className={`p-input-icon-right`}>
      <i className={`pi pi-search mr-1`} />
      <InputText
        placeholder="Nom d'un projet"
        className={`rounded-full pl-3 items-center text-sm ${
          isActive ? 'active-custom-dropdown 2xl:w-[300px]' : '2xl:w-[200px]  '
        }`}
        onChange={(e) => handleSearch(e.target.value)}
        value={value}
      />
    </span>
  );
}

export default DashboardSearch;
