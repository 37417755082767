import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { privateFetch } from '../../../utils/apiHelper';
import Navbar from '../../../components/Navbar/Navbar';
import DashboardDatatable from './DashboardDatatable';
import ManageProject from './ManageProject';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import DashboardPanel from '../../../components/Panel/Dashboard/DashboardPanel';
import { useDebouncedCallback } from 'use-debounce';
import { CONSTANT } from '../../../utils/constantHelper';
import DashboardControls from './DashboardControls';
import DashboardTimeline from './DashboardTimeline';
import { buildPayload } from '../../../utils/stringHelper';

function DashboardPage() {
  const { openProjectDetail } = useNavigationContext();

  const [data, setData] = useState({
    projectList: [],
    totalRows: 0
  });

  const { dashboardContext, setDashboardSort, setDashboardOptions, datatableView } =
    useDashboardContext();

  const [dataSchedule, setDataSchedule] = useState({});
  const [timelineLoading, setTimelineLoading] = useState(true);
  const [datatableLoading, setDatatableLoading] = useState(true);
  const [projectPanelOpen, setProjectPanelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datatableControlsHeight, setDatatableControlsHeight] = useState(0);

  const onSort = (event) => {
    setDashboardSort(
      CONSTANT.dashboard.sortOptions.find(
        (c) => c.sortField === event.sortField && event.sortOrder === c.sortOrder
      )
    );
  };

  useEffect(() => {
    datatableView ? fetchProjectList() : fetchProjectListSchedule();
  }, []);

  useEffect(() => {
    setTimelineLoading(true);
    datatableView ? fetchProjectList() : fetchProjectListSchedule();
  }, [datatableView]);

  useEffect(() => {
    refreshProjectList();
  }, [dashboardContext]);

  const refreshProjectList = useDebouncedCallback(() => {
    datatableView ? fetchProjectList() : fetchProjectListSchedule();
  }, 500);

  const selectProject = (targetProject) => {
    openProjectDetail(targetProject);
  };

  const fetchProjectList = async () => {
    setDatatableLoading(true);
    await privateFetch('GET', `/project/${buildPayload(dashboardContext)}`).then((res) => {
      if (res) {
        setData({ projectList: res.rows, totalRows: res.total_rows });
        setDatatableLoading(false);
      }
    });
  };

  const fetchProjectListSchedule = async () => {
    setTimelineLoading(true);
    await privateFetch('GET', `/project/schedule/${buildPayload(dashboardContext)}`).then((res) => {
      if (res) {
        setDataSchedule(res);
        setTimelineLoading(false);
      }
    });
  };

  const onPage = (event) => {
    setDashboardOptions({
      ...dashboardContext.options,
      page: event.page,
      offset: event.first,
      rows: event.rows
    });
  };

  const createProject = async (projectForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/`,
      `Le projet ${projectForm.name} a bien été créé.`,
      JSON.stringify(projectForm)
    ).then((res) => {
      setProjectPanelOpen(false);
      setLoading(false);
      datatableView ? fetchProjectList() : fetchProjectListSchedule();
      if (res) {
        openProjectDetail(res);
      }
    });
  };

  return (
    <div className={`h-screen w-screen bg-white flex flex-col`}>
      <Navbar />
      <div className="w-full flex flex-row-reverse h-full">
        <DashboardPanel />
        <div className="px-5 py-3 w-full flex flex-col gap-y-3 overflow-scroll ">
          <div className="flex flex-row w-full justify-between items-center">
            <h2 className="text-slate-800">
              {datatableView ? 'Liste des projets' : 'Timeline des projets'}
            </h2>

            <Button
              key="password"
              label={'Nouveau projet'}
              size="small"
              outlined
              iconPos="left"
              icon="pi pi-plus"
              onClick={() => {
                setProjectPanelOpen(true);
              }}
            />
          </div>

          <div className="flex flex-col gap-y-3 w-full overflow-hidden">
            <DashboardControls
              onSort={onSort}
              setDatatableControlsHeight={setDatatableControlsHeight}
            />
            {datatableView ? (
              <DashboardDatatable
                data={data}
                loading={datatableLoading}
                onProjectSelect={selectProject}
                dataSchedule={dataSchedule}
                onSort={onSort}
                datatableControlsHeight={datatableControlsHeight}
                onPage={onPage}
              />
            ) : (
              <DashboardTimeline
                schedule={dataSchedule?.schedule}
                displayStartDate={dataSchedule?.display_start_dt}
                displayEndDate={dataSchedule?.display_end_dt}
                datatableControlsHeight={datatableControlsHeight}
                totalRows={dataSchedule?.total_rows}
                onProjectSelect={selectProject}
                timelineLoading={timelineLoading}
                onPage={onPage}
              />
            )}
          </div>
        </div>
      </div>

      <ManageProject
        loading={loading}
        isOpen={projectPanelOpen}
        handleClose={() => {
          setProjectPanelOpen(false);
        }}
        createProject={createProject}
      />
    </div>
  );
}

export default DashboardPage;
