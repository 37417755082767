import React, { createContext, useContext, useEffect, useState } from 'react';
import { getDashboardContext, saveDashboardContext } from '../utils/dashboardHelper';
import { CONSTANT } from '../utils/constantHelper';
const DashboardContext = createContext(null);

const initial = {
  options: {
    offset: 0,
    rows: 50,
    page: 0
  },
  sortOption: {
    sortLabel: 'Puissance (MW)',
    sortField: 'estimate_power',
    sortOrder: 1,
    sortType: 'numeric'
  },
  filters: {
    query: '',
    stateList: ['in_progress', 'priority'],
    technologyList: [],
    managerList: [],
    clientList: []
  },
  columns: CONSTANT.dashboard.displayableColmuns
};

export const DashboardContextProvider = ({ children }) => {
  const [dashboardContext, setDashboardContext] = useState(getDashboardContext() || initial);
  const [datatableView, setDatatableView] = useState(true);

  useEffect(() => {
    if (dashboardContext) {
      saveDashboardContext(dashboardContext);
    }
  }, [dashboardContext]);

  const setDashboardFilter = (key, value) => {
    setDashboardContext({
      ...dashboardContext,
      filters: {
        ...dashboardContext.filters,
        [key]: value
      }
    });
  };

  const setDashboardSort = (sortOption) => {
    setDashboardContext({
      ...dashboardContext,
      sortOption
    });
  };

  const setDashboardOptions = (options) => {
    setDashboardContext({
      ...dashboardContext,
      options
    });
  };

  const filteringIsActive = () => {
    return dashboardContext.filters !== initial.filters;
  };

  const toggleDashboardColumn = (key, targetValue) => {
    let newColumns = dashboardContext.columns;
    newColumns.find((c) => c.key == key).display = targetValue;
    setDashboardContext({
      ...dashboardContext,
      columns: newColumns
    });
  };

  const columnIsHidden = (key) => {
    return !dashboardContext.columns.find((c) => c.key === key).display;
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboardContext,
        setDashboardFilter,
        setDashboardSort,
        setDashboardOptions,
        filteringIsActive,
        columnIsHidden,
        toggleDashboardColumn,
        datatableView,
        setDatatableView
      }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
