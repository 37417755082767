import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Tag from '../../../components/Display/Tag';
import { getConstant } from '../../../utils/constantHelper';

import { useDashboardContext } from '../../../contexts/DashboardContext';

function DashboardDatatable({
  onProjectSelect,
  data,
  loading,
  onSort,
  datatableControlsHeight,
  onPage
}) {
  const { dashboardContext, setDashboardOptions, columnIsHidden } = useDashboardContext();

  const stepBodyTemplate = (rowData, stepKey) => (
    <Tag
      key={stepKey}
      tag={getConstant(`project.steps.${stepKey}.veryShortLevels`, rowData[stepKey])}
      rounded
    />
  );

  return (
    <div>
      <DataTable
        id={'dashboard-table-with-filters'}
        value={data.projectList}
        lazy
        loading={loading}
        scrollable={true}
        scrollHeight={`calc(100vh - ${datatableControlsHeight}px - 200px)`}
        size="small"
        paginator
        rows={dashboardContext.options.rows}
        first={dashboardContext.options.offset}
        filters={dashboardContext.options.filters}
        totalRecords={data.totalRows}
        alMode={'single'}
        sortField={dashboardContext.sortOption.sortField}
        sortOrder={dashboardContext.sortOption.sortOrder}
        onPage={onPage}
        onSort={onSort}
        selectionMode="single"
        onRowSelect={(e) => onProjectSelect(e.data)}>
        <Column sortable key="name" frozen className="font-bold" field="name" header="Nom"></Column>
        <Column
          key="state"
          field="state"
          header="État"
          hidden={columnIsHidden('state')}
          body={(rowData) => <Tag tag={getConstant('project.state', rowData.state)} />}></Column>
        <Column
          key="technology"
          field="technology"
          header="Techno"
          hidden={columnIsHidden('technology')}
          body={(rowData) => (
            <Tag tag={getConstant('project.technology', rowData.technology)} />
          )}></Column>
        <Column
          sortable
          key="manager"
          field="manager.alias"
          header="Chef de projet"
          hidden={columnIsHidden('manager')}></Column>
        <Column
          key="client"
          field="client.name"
          header="Client"
          hidden={columnIsHidden('client')}></Column>
        <Column
          sortable
          key="identification_step"
          field="identification_step"
          className="w-12"
          header="Id"
          body={(rowData) => stepBodyTemplate(rowData, 'identification_step')}
          hidden={columnIsHidden('steps')}></Column>
        <Column
          sortable
          key="securisation_step"
          field="securisation_step"
          className="w-12"
          header="Sf"
          body={(rowData) => stepBodyTemplate(rowData, 'securisation_step')}
          hidden={columnIsHidden('steps')}></Column>
        <Column
          sortable
          key="study_step"
          field="study_step"
          className="w-12"
          header="Ét"
          body={(rowData) => stepBodyTemplate(rowData, 'study_step')}
          hidden={columnIsHidden('steps')}></Column>
        <Column
          sortable
          key="instruction_step"
          field="instruction_step"
          className="w-12"
          header="In"
          body={(rowData) => stepBodyTemplate(rowData, 'instruction_step')}
          hidden={columnIsHidden('steps')}></Column>
        <Column
          key="prebuilding_step"
          field="prebuilding_step"
          className="w-12"
          header="Pc"
          body={(rowData) => stepBodyTemplate(rowData, 'prebuilding_step')}
          hidden={columnIsHidden('steps')}></Column>
        <Column
          key="cityName"
          field="city.name"
          header="Commune"
          hidden={columnIsHidden('cityName')}></Column>
        <Column
          key="zipCode"
          field="city.zip_code"
          header="ZIP"
          hidden={columnIsHidden('zipCode')}></Column>
        <Column
          key="comcom"
          field="comcom"
          header="Comcom"
          bodyClassName="overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px]"
          hidden={columnIsHidden('comcom')}></Column>
        <Column
          sortable
          key="estimatePower"
          field="estimate_power"
          header="Puissance (MW)"
          hidden={columnIsHidden('estimatePower')}></Column>
        <Column
          key="departement"
          field="city.departement"
          header="Département"
          hidden={columnIsHidden('departement')}></Column>
        <Column
          key="region"
          field="city.region"
          header="Région"
          hidden={columnIsHidden('region')}></Column>
      </DataTable>
    </div>
  );
}

export default DashboardDatatable;
