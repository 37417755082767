import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import Tag from '../../../Display/Tag';
import Pill from '../../../Display/Pill';
import { formatDate, formatDateTime } from '../../../../utils/stringHelper';
import { useProjectCollaboratorContext } from '../../../../contexts/ProjectCollaboratorContext';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';

function PanelCommentListItem({ comment, handleCommentUpdate }) {
  const { openCollaboratorDetail } = useProjectCollaboratorContext();
  const { isCurrentCustomUser } = useAccountContext();

  const propertyList = (
    <div className="flex flex-wrap flex-row gap-2">
      {comment.properties.map((p) => (
        <Pill key={p.id} pill={{ label: p.label }} outlined />
      ))}
    </div>
  );

  const tooltipId = `tooltip-${comment.id}`;

  const isCommentUpdated =
    moment(comment.last_update).format('MMMM Do YYYY, h:mm:ss a') !==
    moment(comment.creation_date).format('MMMM Do YYYY, h:mm:ss a');

  return (
    <div className="w-full flex flex-col gap-y-2 pt-2 pb-3 px-3 rounded bg-slate-100 ">
      <Tooltip
        target={`#${tooltipId}`}
        content={isCommentUpdated ? `Modifié le ${formatDate(comment.last_update)}` : null}
        position="left"
        className="text-sm"
      />
      <div className=" w-full flex flex-row justify-between items-center">
        <h4>{comment.title || 'Commentaire sans titre'}</h4>
        {isCurrentCustomUser(comment.author.id) && (
          <Button
            key="edit"
            text
            size="small"
            rounded
            iconPos="left"
            icon={'pi pi-pencil'}
            onClick={handleCommentUpdate}
          />
        )}
      </div>
      <div className="w-full flex flex-col gap-y-3">
        {comment.properties.length ? propertyList : null}
        <div className="text-sm list-html" dangerouslySetInnerHTML={{ __html: comment.content }} />
        {isCommentUpdated && (
          <p id={tooltipId} className="italic text-sm text-slate-500">
            {'(modifié)'}
          </p>
        )}
        <div className="flex flex-row w-full justify-between items-center pt-3">
          <Tag
            tag={{
              label: comment.author.alias,
              icon: 'contact'
            }}
            onClick={() => openCollaboratorDetail(comment.author)}
          />
          <span className="text-sm italic text-slate-500">{formatDate(comment.creation_date)}</span>
        </div>
      </div>
    </div>
  );
}

export default PanelCommentListItem;
