import React, { useEffect, useMemo } from 'react';
import Timeline, {
  TimelineMarkers,
  CustomMarker,
  TimelineHeaders,
  DateHeader,
  SidebarHeader
} from 'react-calendar-timeline';
import moment from 'moment';
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container';
import 'react-calendar-timeline/lib/Timeline.css';
import 'moment/locale/fr';
import { CONSTANT } from '../../../utils/constantHelper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Paginator } from 'primereact/paginator';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import { Tooltip } from 'primereact/tooltip';

function DashboardTimeline({
  schedule,
  displayStartDate,
  displayEndDate,
  onProjectSelect,
  datatableControlsHeight,
  timelineLoading,
  onPage,
  totalRows
}) {
  const groupRenderer = ({ group }) => {
    if (group) {
      return (
        <div
          className="flex flex-col justify-center h-full pl-1 cursor-pointer"
          onClick={() => onProjectSelect(schedule.find((p) => p.id === group.id))}>
          <span className=" text-sm font-medium overflow-hidden whitespace-nowrap text-ellipsis">
            {group.title}
          </span>
        </div>
      );
    }
  };

  const { dashboardContext } = useDashboardContext();

  const computedItems = useMemo(() => {
    let items = [];
    if (schedule?.length > 0) {
      schedule.forEach((project) => {
        if (project.steps) {
          project.steps.forEach((s, index) => {
            items.push({
              id: s.id,
              group: project.id,
              type: s.type,
              title: CONSTANT.project.steps[s.type]?.label || 'Unknown Step', // Fallback if label not found
              colors: CONSTANT.project.steps[s.type]?.timelineColors,
              start_time: moment(s.start_dt),
              end_time: moment(s.end_dt),
              index: index,
              canMove: false,
              canResize: false
            });
          });
        }
      });
      return items;
    }
  }, [schedule]);

  const itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    if (item) {
      return (
        <div
          {...getItemProps(item.itemProps)}
          className={`${getItemProps(item.itemProps).className} ${
            item.colors
          } rounded !h-6 !border font-semibold`}>
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
          <div
            className="rct-item-content"
            style={{ maxHeight: `${itemContext.dimensions.height}` }}>
            {itemContext.title}
          </div>
          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
        </div>
      );
    }
  };

  const computedGroups = useMemo(() => {
    const groups = [];
    if (schedule?.length > 0) {
      schedule.map((project) => {
        groups.push({
          id: project.id,
          title: project.name,
          stackItems: true
        });
      });
      return groups;
    }
  }, [schedule]);

  const template = {
    layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',

    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center'
          }}>
          {options.first} - {options.last} / {options.totalRecords}
        </span>
      );
    }
  };

  return (
    <div
      className="flex flex-col gap-y-2 overflow-y-auto"
      style={{ height: `calc(100vh - ${datatableControlsHeight}px - 150px)` }}>
      {timelineLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <ProgressSpinner
            style={{ width: '50px', height: '50px' }}
            strokeWidth="4"
            animationDuration=".5s"
          />
        </div>
      ) : (
        <>
          <Timeline
            groupRenderer={groupRenderer}
            itemRenderer={itemRenderer}
            groups={computedGroups || []}
            items={computedItems || []}
            resizeDetector={containerResizeDetector}
            defaultTimeStart={moment(displayStartDate).add(-1, 'months')}
            defaultTimeEnd={moment(displayEndDate).add(1, 'months')}
            maxZoom={10 * 365.24 * 86400 * 1000}
            minZoom={1 * 365.24 * 86400 * 1000}
            canMove={true}
            sidebarWidth={300}>
            <TimelineHeaders className="sticky z-[500] top-0">
              <SidebarHeader>
                {({ getRootProps }) => {
                  return <div className="bg-slate-300" {...getRootProps()}></div>;
                }}
              </SidebarHeader>
              <DateHeader
                unit="primaryHeader"
                className="bg-slate-300"
                intervalRenderer={({ getIntervalProps, intervalContext }) => {
                  return (
                    <div
                      {...getIntervalProps()}
                      className="text-slate-800 font-medium flex h-full flex-row justify-around items-center border-l border-r border-slate-400">
                      <span>{intervalContext.intervalText}</span>
                    </div>
                  );
                }}
              />
              <DateHeader
                className="bg-slate-200"
                labelFormat={'MMM'}
                intervalRenderer={({ getIntervalProps, intervalContext }) => {
                  const isJanuary = intervalContext.interval.startTime.month() == 0;
                  const isDecember = intervalContext.interval.startTime.month() == 11;
                  return (
                    <div
                      {...getIntervalProps()}
                      className={`text-slate-700 flex h-full flex-col justify-center items-center text-sm border-slate-400 ${
                        isJanuary && ' border-l '
                      }
                ${isDecember && ' border-r '}
                `}>
                      {intervalContext.intervalText}
                    </div>
                  );
                }}
              />
            </TimelineHeaders>
            <TimelineMarkers>
              <CustomMarker date={moment()}>
                {({ styles, date }) => {
                  const customStyles = {
                    ...styles,
                    backgroundColor: '#F59E0B',
                    width: '2px',
                    zIndex: 89
                  };
                  return <div style={customStyles} />;
                }}
              </CustomMarker>
            </TimelineMarkers>
          </Timeline>
          <div className=" sticky bottom-0 z-[500]">
            <div className="flex flex-wrap gap-x-3 text-xs text-slate-600 ">
              <p>CTRL + Molette pour zoomer grossièrement</p>
              <p>|</p>
              <p>ALT + Molette pour zoomer précisement</p>
              <p>|</p>
              <p>SHIFT + Molette / Maintenir le clic gauche pour déplacer</p>
            </div>
            <Paginator
              template={template}
              first={dashboardContext.options.offset}
              rows={dashboardContext.options.rows}
              totalRecords={totalRows}
              onPageChange={onPage}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default DashboardTimeline;
