import React, { createContext, useContext, useState, useEffect } from 'react';
import { getNavigation, saveNavigation } from '../utils/navigationHelper';
import { getCurrentReleaseNote } from '../utils/releaseNoteHelper';
import ReleaseNoteDialog from '../components/ReleaseNote/ReleaseNoteDialog';
import { useAccountContext } from './AccountContext';
import BugReportDialog from '../components/BugReport/BugReportDialog';

const NavigationContext = createContext(null);

const initial = {
  showReleaseNote: true,
  activeApp: 'dashboard',
  activeProjectPanel: undefined,
  activeDashboardPanel: undefined,
  activeProject: {
    name: '',
    id: null,
    activeAccordionTabs: []
  },
  recentProjects: []
};

export const NavigationContextProvider = ({ children }) => {
  const [navigationContext, setNavigationContext] = useState(getNavigation() || initial);

  // Release Note Dialog
  const [releaseNoteContent, setReleaseNoteContent] = useState(undefined);

  // Bug Report Dialog
  const [bugReportIsVisible, setBugReportIsVisible] = useState(undefined);
  const { isLoggedIn } = useAccountContext();

  useEffect(() => {
    if (navigationContext && navigationContext.activeApp !== null) {
      saveNavigation(navigationContext);
    }
  }, [navigationContext]);

  useEffect(() => {
    if (navigationContext.showReleaseNote && isLoggedIn) {
      getCurrentReleaseNote() && setReleaseNoteContent(getCurrentReleaseNote());
    }
  }, [isLoggedIn, navigationContext.showReleaseNote]);

  const manageRecentProjects = (targetProject) => {
    const previousActiveProject = navigationContext.activeProject;
    const previousRecentProjects = navigationContext.recentProjects || [];

    let recentProjects = previousRecentProjects.filter((p) => p.id !== targetProject.id);
    if (
      navigationContext.activeProject.id &&
      previousActiveProject &&
      previousActiveProject.id != targetProject.id
    ) {
      recentProjects.unshift(navigationContext.activeProject);
    }
    return recentProjects.slice(0, 7);
  };

  const openProjectDetail = (targetProject) => {
    if (targetProject.id !== navigationContext.activeProject?.id) {
      setNavigationContext({
        ...navigationContext,
        recentProjects: manageRecentProjects(targetProject),
        activeApp: 'project',
        activeProject: {
          id: targetProject.id,
          name: targetProject.name || targetProject.title
        }
      });
    } else {
      setNavigationContext({
        ...navigationContext,
        activeApp: 'project'
      });
    }
  };

  const toggleProjectPanel = (targetKey) => {
    setNavigationContext({
      ...navigationContext,
      activeProjectPanel: navigationContext.activeProjectPanel === targetKey ? undefined : targetKey
    });
  };

  const toggleDashboardPanel = (targetKey) => {
    setNavigationContext({
      ...navigationContext,
      activeDashboardPanel:
        navigationContext.activeDashboardPanel === targetKey ? undefined : targetKey
    });
  };

  const hideReleaseNote = () => {
    setReleaseNoteContent(undefined);
    setNavigationContext({
      ...navigationContext,
      showReleaseNote: false
    });
  };

  const showReleaseNote = () => {
    setNavigationContext({
      ...navigationContext,
      showReleaseNote: true
    });
  };

  const hideBugReport = () => {
    setBugReportIsVisible(false);
  };

  const showBugReport = () => {
    setBugReportIsVisible(true);
  };

  const setPanelCollaborator = (collaboratorId) => {
    let { panel } = navigationContext;
    panel.contact.collaboratorId = collaboratorId;
    setNavigationContext({
      ...navigationContext,
      panel,
      activeProjectPanel: 'contact'
    });
  };

  const setActiveAccordionTabs = (activeTabs) => {
    setNavigationContext({
      ...navigationContext,
      activeProject: {
        ...navigationContext.activeProject,
        activeAccordionTabs: activeTabs
      }
    });
  };

  return (
    <NavigationContext.Provider
      value={{
        navigationContext,
        setNavigationContext,
        openProjectDetail,
        toggleProjectPanel,
        setPanelCollaborator,
        toggleDashboardPanel,
        setActiveAccordionTabs,
        hideReleaseNote,
        showBugReport,
        showReleaseNote
      }}>
      {children}

      <ReleaseNoteDialog content={releaseNoteContent} handleClose={hideReleaseNote} />
      <BugReportDialog visible={bugReportIsVisible} handleClose={hideBugReport} />
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  return useContext(NavigationContext);
};
