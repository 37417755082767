export function getConstant(path, targetValue) {
  let array = CONSTANT;
  path.split('.').forEach((key) => {
    array = array[key];
  });
  if (array.find((c) => c.value === targetValue)) {
    return array.find((c) => c.value === targetValue);
  } else {
    return '';
  }
}

export const CONSTANT = {
  version: '3.1.0',
  maxImageSize: 10 * 1000000,
  dashboard: {
    displayableColmuns: [
      {
        label: 'État',
        key: 'state',
        display: true
      },
      {
        label: 'Technologie',
        key: 'technology',
        display: true
      },
      {
        label: 'Chef de projet',
        key: 'manager',
        display: true
      },
      {
        label: 'Client',
        key: 'client',
        display: true
      },
      {
        label: 'Avancement',
        key: 'current_step',
        display: true
      },
      {
        label: 'Détails des étapes',
        key: 'steps',
        display: true
      },
      {
        label: 'Nom de la ville',
        key: 'cityName',
        display: true
      },
      {
        label: 'Code postal',
        key: 'zipCode',
        display: true
      },
      {
        label: 'Communauté de commune',
        key: 'comcom',
        display: true
      },
      {
        label: 'Puissance estimée',
        key: 'estimatePower',
        display: true
      },
      {
        label: 'Département',
        key: 'departement',
        display: true
      },
      {
        label: 'Région',
        key: 'region',
        display: true
      }
    ],
    sortOptions: [
      {
        sortLabel: 'Puissance (MW)',
        sortField: 'estimate_power',
        sortOrder: 1,
        sortType: 'numeric'
      },
      {
        sortLabel: 'Puissance (MW)',
        sortField: 'estimate_power',
        sortOrder: -1,
        sortType: 'numeric'
      },
      {
        sortLabel: 'Nom',
        sortField: 'name',
        sortOrder: 1,
        sortType: 'alpha'
      },
      {
        sortLabel: 'Nom',
        sortField: 'name',
        sortOrder: -1,
        sortType: 'alpha'
      },
      {
        sortLabel: 'Chef de Projet',
        sortField: 'manager.alias',
        sortOrder: 1,
        sortType: 'alpha'
      },
      {
        sortLabel: 'Chef de Projet',
        sortField: 'manager.alias',
        sortOrder: -1,
        sortType: 'alpha'
      },

      {
        sortLabel: 'Identification',
        sortField: 'identification_step',
        sortOrder: 1,
        sortType: 'numeric'
      },
      {
        sortLabel: 'Identification',
        sortField: 'identification_step',
        sortOrder: -1,
        sortType: 'numeric'
      },
      {
        sortLabel: 'Sécurisation',
        sortField: 'securisation_step',
        sortOrder: 1,
        sortType: 'numeric'
      },
      {
        sortLabel: 'Sécurisation',
        sortField: 'securisation_step',
        sortOrder: -1,
        sortType: 'numeric'
      },
      {
        sortLabel: 'Études',
        sortField: 'study_step',
        sortOrder: 1,
        sortType: 'numeric'
      },
      {
        sortLabel: 'Études',
        sortField: 'study_step',
        sortOrder: -1,
        sortType: 'numeric'
      },
      {
        sortLabel: 'Instruction',
        sortField: 'instruction_step',
        sortOrder: 1,
        sortType: 'numeric'
      },
      {
        sortLabel: 'Instruction',
        sortField: 'instruction_step',
        sortOrder: -1,
        sortType: 'numeric'
      }
    ],
    pagination: [20, 50, 100, 200]
  },
  customUser: {
    type: [
      {
        value: 'ADMIN',
        label: 'Administrateur'
      },
      {
        value: 'MANAGER',
        label: 'Manager'
      },
      {
        value: 'EMPLOYEE',
        label: 'Chef de projet'
      }
    ]
  },
  collaborator: {
    type: [
      {
        value: 'internal',
        label: 'Interne'
      },
      {
        value: 'parcel_owner',
        label: 'Propriétaire/Exploitant'
      },
      {
        value: 'consultant',
        label: 'Externe'
      },
      {
        value: 'other',
        label: 'Autre'
      }
    ],
    subtype: [
      {
        value: 'po_operator',
        label: 'Exploitant'
      },
      {
        value: 'po_owner_operator',
        label: 'Propriétaire/Exploitant'
      },
      {
        value: 'po_owner',
        label: 'Propriétaire'
      },
      {
        value: 'po_bare_owner',
        label: 'Nu-propriétaire'
      },
      {
        value: 'po_usufructuary',
        label: 'Usufruitier'
      },
      {
        value: 'po_joint_owner',
        label: 'Propriétaire indivision'
      },
      {
        value: 'po_bare_joint_owner',
        label: 'Nu-propriétaire indivision'
      },
      {
        value: 'po_usufructuary_joint',
        label: 'Usufruitier indivision'
      }
    ],
    gender: [
      {
        value: 'male',
        label: 'Homme'
      },
      {
        value: 'female',
        label: 'Femme'
      }
    ]
  },
  company: {
    type: [
      {
        value: 'client',
        label: 'Client'
      },
      {
        value: 'administration',
        label: 'Administration'
      },
      {
        value: 'agricultural_design_office',
        label: 'BE Agri'
      },
      {
        value: 'environmental_design_office',
        label: 'BE Enviro'
      },
      {
        value: 'urbanism_design_office',
        label: 'BE Urba'
      },
      {
        value: 'geometrician',
        label: 'Géomètre'
      },
      {
        value: 'geotechnician',
        label: 'Géotechnicien'
      },
      {
        value: 'lawyer',
        label: 'Avocat'
      },
      {
        value: 'notary',
        label: 'Notaire'
      },
      {
        value: 'architect',
        label: 'Architecte'
      },
      {
        value: 'bailiff',
        label: 'Huissier'
      },
      {
        value: 'spv',
        label: 'SPV'
      },
      {
        value: 'other',
        label: 'Autre'
      }
    ]
  },
  panel: [
    {
      value: 'comment',
      label: 'Commentaires'
    },
    {
      value: 'contact',
      label: 'Contacts'
    },
    {
      value: 'event',
      label: 'Décisions importantes'
    }
  ],
  project: {
    state: [
      {
        value: 'in_progress',
        bgColor: 'bg-custom-green',
        label: 'En cours'
      },
      {
        value: 'priority',
        bgColor: 'bg-custom-green-dark',
        label: 'Prioritaire'
      },
      {
        value: 'stand_by',
        bgColor: 'bg-custom-orange',
        label: 'Stand by'
      },
      {
        value: 'close',
        bgColor: 'bg-custom-red',
        label: 'Archivé'
      }
    ],
    technology: [
      {
        value: 'pv',
        label: 'PV'
      },
      {
        value: 'wind',
        label: 'WIND'
      },
      {
        value: 'battery',
        label: 'Batterie'
      },
      {
        value: 'step',
        label: 'Retenues'
      }
    ],
    origin: [
      {
        value: 'internal',
        label: 'Scan interne'
      },
      {
        value: 'client',
        label: 'Client'
      },
      {
        value: 'opportunity',
        label: 'Opportunité'
      },
      {
        value: 'other',
        label: 'Autre'
      }
    ],
    structure: [
      {
        value: 'fixed',
        label: 'Fixe'
      },
      {
        value: 'tracker',
        label: 'Tracking'
      },
      {
        value: 'roof_construction',
        label: 'Construction toît'
      },
      {
        value: 'roof_renovation',
        label: 'Rénovation toît'
      },
      {
        value: 'shade_house',
        label: 'Ombrière'
      },
      {
        value: 'floating',
        label: 'Flottant'
      },
      {
        value: 'other',
        label: 'Autre'
      }
    ],
    currentStep: [
      {
        value: 'init',
        label: 'Lancement'
      },
      {
        value: 'identification',
        label: 'Identification'
      },
      {
        value: 'securisation',
        label: 'Sécurisation'
      },
      {
        value: 'study',
        label: 'Études'
      },
      {
        value: 'instruction',
        label: 'Instruction'
      },
      {
        value: 'prebuilding',
        label: 'Post-instruction'
      }
    ],
    steps: {
      identification_step: {
        label: 'Identification',
        timelineColor: null,
        additionnalLabel: "l'identification",
        levels: [
          {
            value: 0,
            label: '0 - Projet annulé',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: '1 - Contrainte forte identifiée',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: '2 - Identifié',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: '3 - Contact territoire ou contact propriétaire',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: '4 - Validé pour sécurisation foncière',
            bgColor: 'bg-custom-green-dark'
          }
        ],
        shortLevels: [
          {
            value: 0,
            label: 'Identification 0',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: 'Identification 1',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: 'Identification 2',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: 'Identification 3',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: 'Identification 4',
            bgColor: 'bg-custom-green-dark'
          }
        ],
        veryShortLevels: [
          {
            value: 0,
            label: 'I0',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: 'I1',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: 'I2',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: 'I3',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: 'I4',
            bgColor: 'bg-custom-green-dark'
          }
        ]
      },
      securisation_step: {
        label: 'Sécurisation foncière',
        timelineColors: '!bg-slate-100 !text-slate-800 !border-slate-800',
        additionnalLabel: 'la sécurisation',
        levels: [
          {
            value: 0,
            label: '0 - Projet annulé',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: '1 - Contraintes fortes propriétaire',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: '2 - Propriétaires Identifiés',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: '3 - Propriétaires contactés en cours de signature',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: '4 - Foncier signé totalement',
            bgColor: 'bg-custom-green-dark'
          }
        ],
        shortLevels: [
          {
            value: 0,
            label: 'Sécurisation 0',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: 'Sécurisation 1',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: 'Sécurisation 2',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: 'Sécurisation 3',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: 'Sécurisation 4',
            bgColor: 'bg-custom-green-dark'
          }
        ],
        veryShortLevels: [
          {
            value: 0,
            label: 'S0',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: 'S1',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: 'S2',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: 'S3',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: 'S4',
            bgColor: 'bg-custom-green-dark'
          }
        ]
      },
      study_step: {
        label: 'Études',
        timelineColors: '!bg-slate-300 !text-slate-800 !border-slate-800',
        additionnalLabel: 'les études',
        levels: [
          {
            value: 0,
            label: '0 - Études annulées',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: '1 - Contraintes fortes',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: '2 - Études identifiées',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: '3 - Études en cours de réalisation',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: '4 - Études Terminées',
            bgColor: 'bg-custom-green-dark'
          }
        ],
        shortLevels: [
          {
            value: 0,
            label: 'Études 0',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: 'Études 1',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: 'Études 2',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: 'Études 3',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: 'Études 4',
            bgColor: 'bg-custom-green-dark'
          }
        ],
        veryShortLevels: [
          {
            value: 0,
            label: 'E0',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: 'E1',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: 'E2',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: 'E3',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: 'E4',
            bgColor: 'bg-custom-green-dark'
          }
        ]
      },
      instruction_step: {
        label: 'Instruction',
        timelineColors: '!bg-slate-600 !text-slate-100 !border-slate-600',
        additionnalLabel: "l'instruction",
        levels: [
          {
            value: 0,
            label: '0 - Projet annulé',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: '1 - Contraintes fortes (recours, ...)',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: '2 - Préparation dépôt dossier',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: '3 - Dossier en instruction',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: '4 - Autorisation obtenue',
            bgColor: 'bg-custom-green-dark'
          }
        ],
        shortLevels: [
          {
            value: 0,
            label: 'Instruction 0',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: 'Instruction 1',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: 'Instruction 2',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: 'Instruction 3',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: 'Instruction 4',
            bgColor: 'bg-custom-green-dark'
          }
        ],
        veryShortLevels: [
          {
            value: 0,
            label: 'I0',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: 'I1',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: 'I2',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: 'I3',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: 'I4',
            bgColor: 'bg-custom-green-dark'
          }
        ]
      },
      prebuilding_step: {
        label: 'Post-instruction',
        timelineColors: '!bg-slate-800 !text-slate-100 !border-slate-800',
        additionnalLabel: 'la post-instruction',
        levels: [
          {
            value: 0,
            label: '0 - Projet annulé',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: '1 - Contraintes fortes (recours, ...)',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: '2 - Préparation dépôt dossier',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: '3 - Dossier en instruction',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: '4 - Autorisation obtenue',
            bgColor: 'bg-custom-green-dark'
          }
        ],
        shortLevels: [
          {
            value: 0,
            label: 'Post-instruction 0',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: 'Post-instruction 1',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: 'Post-instruction 2',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: 'Post-instruction 3',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: 'Post-instruction 4',
            bgColor: 'bg-custom-green-dark'
          }
        ],
        veryShortLevels: [
          {
            value: 0,
            label: 'PC0',
            bgColor: 'bg-custom-red'
          },
          {
            value: 1,
            label: 'PC1',
            bgColor: 'bg-custom-orange'
          },
          {
            value: 2,
            label: 'PC2',
            bgColor: 'bg-slate-600'
          },
          {
            value: 3,
            label: 'PC3',
            bgColor: 'bg-custom-green'
          },
          {
            value: 4,
            label: 'PC4',
            bgColor: 'bg-custom-green-dark'
          }
        ]
      }
    },
    riskTypes: [
      {
        value: 'urbanism_risk',
        label: 'Urbanisme'
      },
      {
        value: 'environment_risk',
        label: 'Environnement'
      },
      {
        value: 'territory_risk',
        label: 'Acceptation territoire'
      },
      {
        value: 'connection_risk',
        label: 'Raccordement'
      }
    ],
    riskLevels: [
      {
        value: 0,
        label: 'Aucun risques',
        bgColor: 'bg-custom-green-dark',
        textColor: 'text-custom-green-dark'
      },
      {
        value: 1,
        label: 'Risques faibles',
        bgColor: 'bg-custom-green',
        textColor: 'text-custom-green'
      },
      {
        value: 2,
        label: 'Risques modérés',
        bgColor: 'bg-custom-orange',
        textColor: 'text-custom-orange'
      },
      {
        value: 3,
        label: 'Risques forts',
        bgColor: 'bg-custom-red',
        textColor: 'text-custom-red'
      }
    ],
    securisation: {
      onBoarding: [
        {
          label: "Terminer l'identification",
          value: 'identification'
        }
      ],
      ownershipStates: [
        {
          value: 'signed',
          bgColor: 'bg-custom-green',
          label: 'Signée'
        },
        {
          value: 'held',
          bgColor: 'bg-custom-orange',
          label: 'En attente'
        },
        {
          value: 'refused',
          bgColor: 'bg-custom-red',
          label: 'Refusée'
        }
      ],
      parcelTypes: [
        {
          value: true,
          bgColor: 'bg-custom-red',
          label: 'Critique'
        },
        {
          value: false,
          label: 'Normale'
        }
      ]
    },
    study: {
      onBoarding: [
        {
          label: "Sécuristation à l'état 3 ou 4",
          value: 'securisation'
        },
        {
          label: 'Ajouter le fichier KML au projet',
          value: 'kml'
        },
        {
          label: 'Renseigner les différents niveaux de risques',
          value: 'risks'
        },
        {
          label: "Plannifier les périodes d'étude, d'instruction et de post-instruction",
          value: 'schedule'
        }
      ],
      state: [
        {
          value: 'in_progress',
          bgColor: 'bg-custom-orange',
          label: 'En cours'
        },
        {
          value: 'validated',
          bgColor: 'bg-custom-green-dark',
          label: 'Terminée'
        }
      ],
      type: [
        {
          value: 'environmental',
          label: 'Environnement'
        },
        {
          value: 'technical',
          label: 'Technique'
        },
        {
          value: 'agricultural',
          label: 'Agricole'
        },
        {
          value: 'other',
          label: 'Autre'
        }
      ]
    },
    instruction: {
      onBoarding: [
        {
          label: 'Démarrer les études',
          value: 'study'
        }
      ],
      state: [
        {
          value: 'in_progress',
          bgColor: 'bg-custom-orange',
          label: 'En cours'
        },
        {
          value: 'validated',
          bgColor: 'bg-custom-green-dark',
          label: 'Terminée'
        }
      ],
      type: [
        {
          value: 'environmental',
          label: 'Environnement'
        },
        {
          value: 'technical',
          label: 'Technique'
        },
        {
          value: 'administrative',
          label: 'Administrative'
        },
        {
          value: 'other',
          label: 'Autre'
        }
      ]
    },
    prebuilding: {
      onBoarding: [
        {
          label: "Démarrer l'instruction",
          value: 'instruction'
        }
      ],
      state: [
        {
          value: 'in_progress',
          bgColor: 'bg-custom-orange',
          label: 'En cours'
        },
        {
          value: 'validated',
          bgColor: 'bg-custom-green-dark',
          label: 'Terminée'
        }
      ],
      type: [
        {
          value: 'environmental',
          label: 'Environnement'
        },
        {
          value: 'technical',
          label: 'Technique'
        },
        {
          value: 'budget',
          label: 'Budget'
        },
        {
          value: 'other',
          label: 'Autre'
        }
      ]
    }
  },
  admin: {
    accountType: [
      {
        value: 'ADMIN',
        label: 'Administrateur',
        bgColor: 'bg-custom-green-dark'
      },
      {
        value: 'MANAGER',
        label: 'Manager',
        bgColor: 'bg-custom-green-dark'
      },
      {
        value: 'EMPLOYEE',
        label: 'Chef de projet',
        bgColor: 'bg-custom-green'
      }
    ]
  },
  misc: {
    propertyTypes: [
      {
        label: 'Commentaire',
        value: 'comment'
      },
      {
        label: 'Décision importante',
        value: 'event'
      }
    ]
  }
};
