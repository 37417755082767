import React from 'react';
import { CONSTANT } from '../../utils/constantHelper';
import { getImage } from '../../utils/imageHelper';

function Layout({ children }) {
  return (
    <div className="h-screen w-screen bg-slate-700 text-slate-50">
      <div className="h-full flex flex-col justify-between p-5">
        <div className="flex flex-row gap-5 items-center">
          <img src={getImage('logo-aden.png')} alt="Logo du Collectif Node" className="h-12 w-12" />
          <span className="text-2xl font-semibold">ADEN - OHM</span>
        </div>
        {children}
        <div className="flex flex-row gap-3 text-slate-300">
          <span>{`Version ${CONSTANT.version}`}</span>
          <span>-</span>
          <span>
            {`Conçu, développé et maintenu par le `}{' '}
            <a
              href="https://collectifnode.fr"
              target="_blank"
              rel="noreferrer"
              className="text-yellow-500 hover:underline">
              Collectif Node
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Layout;
