import { MultiSelect } from 'primereact/multiselect';
import React, { useMemo } from 'react';

function DashboardFilter({ options, value, onChange, emptyLabel, optionLabel, showFilter }) {
  const isActive = useMemo(() => value && value.length, [value]);

  return (
    <MultiSelect
      value={value}
      onChange={(e) => onChange(e.target.value)}
      options={options}
      optionLabel={optionLabel || 'label'}
      placeholder={emptyLabel}
      scrollHeight={'350px'}
      className={` rounded-full pl-1 items-center text-sm ${isActive && 'active-custom-dropdown'}`}
      showSelectAll={false}
      filter={showFilter}
      maxSelectedLabels={2}
      selectedItemsLabel={`${value.length} sélectionnés`}
      showClear
    />
  );
}

export default DashboardFilter;
