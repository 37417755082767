import React from 'react';
import { Dropdown } from 'primereact/dropdown';

function DashboardRows({ options, value, onChange }) {
  const rowTemplate = (option, props) => {
    if (option) return `${option} lignes`;
    return <span>{props.placeholder}</span>;
  };
  return (
    <Dropdown
      value={value}
      onChange={(e) => onChange(e.target.value)}
      options={options}
      placeholder="Nb de lignes"
      valueTemplate={rowTemplate}
      itemTemplate={rowTemplate}
      className={`pl-1 items-center text-sm font-medium`}
    />
  );
}

export default DashboardRows;
