import React, { useMemo, useRef, useEffect } from 'react';
import DashboardSearch from '../../../components/Dashboard/DashboardSearch';
import DashboardFilter from '../../../components/Dashboard/DashboardFilter';
import DashboardRows from '../../../components/Dashboard/DashboardRows';
import DashboardSort from '../../../components/Dashboard/DashboardSort';
import { Button } from 'primereact/button';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import { CONSTANT } from '../../../utils/constantHelper';
import { useAccountContext } from '../../../contexts/AccountContext';
import { useCompanyContext } from '../../../contexts/CompanyContext';
import { useCollaboratorContext } from '../../../contexts/CollaboratorContext';
import { useNavigationContext } from '../../../contexts/NavigationContext';

function DashboardControls({ onSort, setDatatableControlsHeight }) {
  const {
    dashboardContext,
    setDashboardFilter,
    setDashboardOptions,
    datatableView,
    setDatatableView
  } = useDashboardContext();

  const { navigationContext } = useNavigationContext();

  const handleFilter = (key, value) => {
    setDashboardFilter(key, value);
  };

  const onRows = (event) => {
    setDashboardOptions({
      ...dashboardContext.options,
      rows: event.rows
    });
  };

  const { collaboratorContext } = useCollaboratorContext();

  const { companyContext, getClientList } = useCompanyContext();
  const ref = useRef(null);
  const clientList = useMemo(() => getClientList(), [companyContext]);

  const { isAdmin } = useAccountContext();

  useEffect(() => {
    setDatatableControlsHeight(ref.current.clientHeight);
  }, [navigationContext.activeDashboardPanel]);

  return (
    <div className={`flex flex-row gap-3 w-full max-w-full  justify-between h-auto`} ref={ref}>
      <div className="flex flex-wrap gap-2 items-center">
        <Button
          icon={`pi ${datatableView ? 'pi-calendar' : 'pi-list'}`}
          onClick={(e) => setDatatableView(!datatableView)}
          className="w-7 h-7 rounded-full"
          rounded
          outlined
        />
        <DashboardSearch
          handleSearch={(value) => handleFilter('query', value)}
          value={dashboardContext.filters.query}
        />
        <DashboardFilter
          key="state"
          options={CONSTANT.project.state}
          value={dashboardContext.filters.stateList}
          onChange={(value) => handleFilter('stateList', value)}
          emptyLabel="État"
        />
        <DashboardFilter
          key="technology"
          options={CONSTANT.project.technology}
          value={dashboardContext.filters.technologyList}
          onChange={(value) => handleFilter('technologyList', value)}
          emptyLabel="Techno"
        />
        <DashboardFilter
          key="manager"
          options={collaboratorContext.internalCollaborators}
          value={dashboardContext.filters.managerList}
          onChange={(value) => handleFilter('managerList', value)}
          emptyLabel="Chef de projet"
          optionLabel={'alias'}
          showFilter
        />
        {isAdmin && (
          <DashboardFilter
            key="client"
            options={clientList}
            value={dashboardContext.filters.clientList}
            onChange={(value) => handleFilter('clientList', value)}
            emptyLabel="Client"
            optionLabel={'name'}
          />
        )}
      </div>
      <div className="flex flex-row gap-x-3 h-fit">
        <DashboardSort
          options={CONSTANT.dashboard.sortOptions}
          value={dashboardContext.sortOption}
          onChange={onSort}
        />

        <DashboardRows
          options={CONSTANT.dashboard.pagination}
          value={dashboardContext.options.rows}
          onChange={(rows) => onRows({ ...dashboardContext.options, rows })}
        />
      </div>
    </div>
  );
}

export default DashboardControls;
